import React, { useState } from 'react';
import {
  Button,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  Chip,
  LinearProgress,
  Tooltip,
} from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone';
import './Whatsapp.css';
import axiosInstance from 'src/utils/axios';
import { ApiEndpoints } from 'src/config';
import { useSnackbar } from 'notistack';
import WATemplateLocationInputSearch from './WATemplateLocationInputSearch';

const WhatsAppTemplateHeader = ({ values, setFieldValue, errors, touched, handleChange  }) => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  // Allowed file types and sizes
  const allowedImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
  const allowedVideoTypes = ['video/mp4', 'video/3gpp'];
  const allowedDocTypes = ['application/pdf'];
  const maxImageSize = 5 * 1024 * 1024; // 5 MB
  const maxVideoSize = 10 * 1024 * 1024; // 10 MB
  const maxDocSize = 10 * 1024 * 1024; // 10 MB

  const uploadTemplateMedia = (file, mediaType) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', mediaType);
  
    // Extract file details
    const mime = file.type; // MIME type of the file
    const length = file.size; // File size in bytes
    const fileType = file.name.split('.').pop().toLowerCase(); // File extension/type
  
    // Configure axios request with progress tracking
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setUploadProgress(progress);
      },
    };
  
    // API call
    axiosInstance
      .post(`${ApiEndpoints.WA_TEMPLATE_MEDIA_UPLOAD}?type=${fileType}&mimetype=${mime}&length=${length}`, formData, config)
      .then((response) => {
        if (response?.data?.status === 200) {
          setUploadProgress(100);
          setIsUploading(false);
          const uploadedData = response?.data?.data;
  
          // Update the form values with uploaded document response and file details
          setFieldValue('uploadedDocumentResponse', {
            ...uploadedData,
            type: fileType,
            mime: mime,
            length: length,
          });
          enqueueSnackbar('File uploaded successfully', { variant: 'success' });
  
          // Show the preview after successful upload for IMAGE or VIDEO
          if (mediaType === 'IMAGE' || mediaType === 'VIDEO') {
            const reader = new FileReader();
            reader.onload = (e) => {
              setFieldValue('filePreview', e.target.result);
            };
            reader.readAsDataURL(file);
          } else {
            setFieldValue('filePreview', null);
          }
        } else {
          enqueueSnackbar('Error uploading file', { variant: 'error' });
          setIsUploading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar('Error uploading file', { variant: 'error' });
        setIsUploading(false);
      });
  };

  const handleFileChange = (file) => {
    if (file) {
      let isValid = false;
      const mediaType = values.messageHeaderType;
      // Check file type and size
      if (mediaType === 'IMAGE') {
        isValid = allowedImageTypes.includes(file.type) && file.size <= maxImageSize;
      } else if (mediaType === 'VIDEO') {
        isValid = allowedVideoTypes.includes(file.type) && file.size <= maxVideoSize;
      } else if (mediaType === 'DOCUMENT') {
        isValid = allowedDocTypes.includes(file.type) && file.size <= maxDocSize;
      }

      if (isValid) {
        setFieldValue('fileName', file.name);
        setFieldValue('fileError', '');
        setIsUploading(true);
        // Start file upload
        uploadTemplateMedia(file, mediaType);
      } else {
        setFieldValue('fileError', 'Invalid file type or size exceeds the limit');
      }
    }
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    handleFileChange(file);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    handleFileChange(file);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  return (
    <div>
      
        <div className="msg-body">
          <h4>
            Message header
            <Tooltip title="Highlight your message by adding acustom text or media">
            <InfoOutlined fontSize="15" sx={{ ml: 0.2 }} />
          </Tooltip>
            <Chip label="Optional" sx={{ borderRadius: 1, height: 20, ml: 1 }} />
          </h4>
          <FormControl>
            <RadioGroup
              name="messageHeaderType"
              row
              onChange={(e) => {
                setFieldValue('messageHeaderType', e.target.value);
                setFieldValue('headerText', null);
                setFieldValue('fileName', 'Please upload a file');
                setFieldValue('filePreview', null);
                setFieldValue('fileError', '');
                setFieldValue('uploadedDocumentResponse', null);
                setUploadProgress(0);
              }}
              value={values?.messageHeaderType}
            >
              {values?.templateCategory?.requiredComponents
                ?.find((item) => item?.type === 'HEADER')
                ?.allowedType?.map((allowedTypeItem, index) => (
                  <FormControlLabel
                    key={index}
                    value={allowedTypeItem}
                    control={<Radio />}
                    label={allowedTypeItem}
                  />
                ))}
            </RadioGroup>
          </FormControl>
          {values?.messageHeaderType === 'TEXT' && (
            <div id="textFields">
              <TextField
                name="headerText"
                fullWidth
                label="New to Odio ?"
                value={values?.headerText}
                id="fullWidth"
                onChange={(e) => {
                  setFieldValue('headerText', e.target.value);
                }}
              />
            </div>
          )}
          {/* File upload and progress */}
          {values?.messageHeaderType === "LOCATION" ? 
          <WATemplateLocationInputSearch 
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          handleChange={handleChange}
          /> 
          : 
          ['IMAGE', 'VIDEO', 'DOCUMENT'].includes(values?.messageHeaderType) && (
            <>
              <div
                id={`${values?.messageHeaderType?.toLowerCase()}Fields`}
                className="file-upload"
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                style={{
                  border: '2px dashed #ccc',
                  padding: '20px',
                  textAlign: 'center',
                  marginBottom: '10px',
                  display: 'flex',
                  gap: '2rem',
                  backgroundColor: '#f7fafc',
                }}
              >
                {!isUploading ? (
                  <>
                    <input
                      type="file"
                      id={`${values?.messageHeaderType?.toLowerCase()}Upload`}
                      name={`${values?.messageHeaderType?.toLowerCase()}Upload`}
                      accept={
                        values.messageHeaderType === 'IMAGE'
                          ? allowedImageTypes.join(',')
                          : values.messageHeaderType === 'VIDEO'
                          ? allowedVideoTypes.join(',')
                          : allowedDocTypes.join(',')
                      }
                      onChange={handleFileInputChange}
                      style={{ display: 'none' }}
                    />
                    <label htmlFor={`${values.messageHeaderType.toLowerCase()}Upload`}>
                      <Button
                        variant="outlined"
                        component="span"
                        sx={{
                          backgroundColor: '#fff',
                          padding: '1rem',
                          color: '#000',
                          borderRadius: '6px',
                          border: '1px solid #e1e1e1',
                          fontWeight: 'normal',
                          width: '300px',
                        }}
                        endIcon={<CloudUploadTwoToneIcon />}
                      >
                        {values.fileName || 'Please upload a file'}
                      </Button>
                    </label>
                  </>
                ) : (
                  <LinearProgress
                    variant="determinate"
                    value={uploadProgress}
                    sx={{ width: '100%' }}
                  />
                )}
                <div>
                  <p>Drag and drop files here or click to upload</p>
                  <p style={{ fontSize: '12px' }}>
                    Accepted file types:{' '}
                    {values.messageHeaderType === 'IMAGE'
                      ? '.JPEG,.JPG,.PNG within 5mb size'
                      : values.messageHeaderType === 'VIDEO'
                      ? '.mp4,.3gpp within 10mb size'
                      : '.PDF within 10mb size'}
                  </p>
                </div>
              </div>
              {values?.fileError && <p style={{ color: 'red' }}>{values.fileError}</p>}
            </>
          )}

          {/* Preview for images and videos */}
          {values?.filePreview && values?.messageHeaderType === 'IMAGE' && (
            <div>
              <img src={values?.filePreview} alt="Uploaded Preview" style={{ maxWidth: '100%' }} />
            </div>
          )}

          {values?.filePreview && values?.messageHeaderType === 'VIDEO' && (
            <div>
              <video controls style={{ maxWidth: '100%' }}>
                <source src={values?.filePreview} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          )}

          {/* Preview for documents */}
          {values?.fileName && values?.messageHeaderType === 'DOCUMENT' && !values?.fileError && (
            <div>
              <p>Document Uploaded: {values?.fileName}</p>
            </div>
          )}
        </div>
    </div>
  );
};

export default WhatsAppTemplateHeader;
