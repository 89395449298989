import { useEffect, useMemo } from "react";
import { Button, TextField, Grid, RadioGroup, FormControlLabel, Radio, Box, Autocomplete, FormControl, FormHelperText } from "@mui/material";
import { useLoadScript } from "@react-google-maps/api";
import usePlaceAutoComplete, { getGeocode, getLatLng } from "use-places-autocomplete";

// Helper function to get the map image and link
const REACT_APP_MAP_API_KEY = "AIzaSyDjveTAYpzhnDdS3hSJ0_3kfP39D7r2CCw";
export const getMapImageAndLink = (latitude, longitude, imageParams = {}) => {
  const { zoom = 15, width = 300, height = 300, format = "jpg", mapType = "roadmap" } = imageParams;  
  const image = `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&markers=color:blue%7C${latitude},${longitude}&zoom=${zoom}&size=${width}x${height}&format=${format}&maptype=${mapType}&key=${REACT_APP_MAP_API_KEY}`;
  const link = `https://maps.google.com/maps?z=${zoom}&q=loc:${latitude}+${longitude}`;
  return { image, link };
};

const LIBRARIES = ["places"];

const WATemplateLocationInputSearch = ({
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
}) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: REACT_APP_MAP_API_KEY,
    libraries: LIBRARIES,
  });

  const {
    value: searchText,
    setValue: setSearchText,
    suggestions: { data, loading },
    init,
  } = usePlaceAutoComplete();

  useEffect(() => {
    if (isLoaded) init();
  }, [isLoaded]);

  const placesOptions = useMemo(() => {
    if (!data) return [];
    return data.map((place) => ({
      label: place.description,
      value: place.place_id,
    }));
  }, [data]);

  const parseSelectedLocation = async (label) => {
    if (!label) return;
    const result = await getGeocode({ address: label });
    if (result.length === 0) return;
    const { lat, lng } = getLatLng(result[0]);
    setFieldValue("latitude", lat);
    setFieldValue("longitude", lng);
  };

  useEffect(() => {
    if (values.label?.label) {
      parseSelectedLocation(values.label.label);
    }
  }, [values.label]);

  const viewLatLongOnMap = () => {
    if (!values.latitude || !values.longitude) return;
    const latLng = `${values.latitude},${values.longitude}`;
    window.open(`https://www.google.com/maps?q=${latLng}`, "_blank");
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Name"
          name="name"
          value={values.name}
          onChange={handleChange}
          error={touched.name && Boolean(errors.name)}
          helperText={touched.name && errors.name}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Address"
          name="address"
          value={values.address}
          onChange={handleChange}
          error={touched.address && Boolean(errors.address)}
          helperText={touched.address && errors.address}
        />
      </Grid>
      <Grid item xs={12}>
        <RadioGroup
          row
          name="mode"
          value={values.mode}
          onChange={handleChange}
        >
          <FormControlLabel value="search" control={<Radio />} label="Search Location" />
          <FormControlLabel value="manual" control={<Radio />} label="Latitude & Longitude" />
        </RadioGroup>
      </Grid>
      {values.mode === "manual" ? (
        <>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Latitude"
              name="latitude"
              value={values.latitude}
              onChange={handleChange}
              error={touched.latitude && Boolean(errors.latitude)}
              helperText={touched.latitude && errors.latitude}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Longitude"
              name="longitude"
              value={values.longitude}
              onChange={handleChange}
              error={touched.longitude && Boolean(errors.longitude)}
              helperText={touched.longitude && errors.longitude}
            />
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          <FormControl fullWidth>
            <Autocomplete
              disablePortal
              name="search_location"
              id="combo-box-demo"
              options={placesOptions}
              value={values.label || null}
              onChange={(e, newValue) => {
                setFieldValue("label", newValue);
                parseSelectedLocation(newValue?.label);
              }}
              inputValue={searchText}
              onInputChange={(e, newValue) => setSearchText(newValue)}
              getOptionLabel={(option) => option.label || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search Location"
                  error={Boolean(errors.search_location)}
                />
              )}
            />
            <FormHelperText error={Boolean(errors.search_location)}>
              {errors.search_location}
            </FormHelperText>
          </FormControl>
        </Grid>
      )}
      <Grid item xs={12}>
        <Button
          variant="contained"
          onClick={viewLatLongOnMap}
          disabled={!values.latitude || !values.longitude}
        >
          View in Map
        </Button>
      </Grid>
    </Grid>
  );
};

export default WATemplateLocationInputSearch;
