import React, { useEffect } from 'react';
import {
  Button, TextField, Menu, MenuItem, Divider, Paper, MenuList,
  ListItemText, ListItemIcon,
  InputLabel,
  FormControl
} from '@mui/material';
import { Reply as ReplyIcon, Launch as LaunchIcon, Phone as PhoneIcon, Description as DescriptionIcon, DeleteTwoTone as DeleteTwoToneIcon, ImportExportOutlined as ImportExportOutlinedIcon } from '@mui/icons-material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ErrorMessage, Field, FieldArray } from 'formik';
import PhoneNumberInput from 'src/components/hook-form/PhoneNumberInput';

const WhatsAppTemplateButtons = ({ values, setFieldValue,errors }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (option) => {
    if (option === 'QUICK_REPLY') {
      // Find and update the 'Custom replies' option in the buttons
      const updatedButtons = values.customButtons.map((button) =>
        button.option === 'Custom Replies'
          ? { ...button, bodies: [...button.bodies, {id: Date.now(), text: '',type: option }] }
          : button
      );
      setFieldValue('customButtons', updatedButtons);
    } else {
      // Add to 'Call to action' bodies
      const callToAction = values.customButtons.find(button => button.option === 'Call to action');
      const limitReached =
        (option === 'URL' && callToAction.bodies?.filter((body)=> body?.type === 'URL')?.length >= 2) ||
        (['Phone', 'Coupon code'].includes(option) && callToAction.bodies.some(body => body.type === option));

      if (!limitReached) {
        setFieldValue(
          'customButtons',
          values.customButtons.map((button) =>
            button.option === 'Call to action'
              ? { ...button, bodies: [...button.bodies, { id: Date.now(), text: '', type: option }] }
              : button
          )
        );
      }
    }
    handleClose();
  };

    useEffect(() => {
        const combinedBodies = values?.customButtons?.reduce((acc, current) => {
            return acc.concat(current.bodies);
        }, []);
        setFieldValue('addedButtons',combinedBodies);
    },[values?.customButtons]);

  const handleDeleteBody = (buttonOption, id) => {
    setFieldValue(
      'customButtons',
      values.customButtons.map((button) =>
        button.option === buttonOption
          ? { ...button, bodies: button.bodies.filter((body) => body.id !== id) }
          : button
      )
    );
  };

  const handleTextFieldChange = (buttonOption, bodyId, fieldName, newValue) => {
    setFieldValue(
      'customButtons',
      values.customButtons.map((btn) =>
        btn.option === buttonOption
          ? {
              ...btn,
              bodies: btn.bodies.map((b) =>
                b.id === bodyId ? { ...b, [fieldName]: newValue } : b
              ),
            }
          : btn
      )
    );
  };  

  const onDragEnd = (result) => {
    const { source, destination } = result;
  
    // Check if there's no destination (user dropped outside the list)
    if (!destination) return;
  
    // Check if the source and destination are the same (i.e., same button option)
    if (source.droppableId !== destination.droppableId) return;
  
    // Reorder the items within the same button option
    const buttonIndex = parseInt(source.droppableId, 10);
    const updatedButtons = [...values.customButtons];
    const buttonBodies = [...updatedButtons[buttonIndex].bodies];
  
    // Perform the reordering
    const [movedItem] = buttonBodies.splice(source.index, 1);
    buttonBodies.splice(destination.index, 0, movedItem);
  
    // Update the state with the reordered bodies
    updatedButtons[buttonIndex].bodies = buttonBodies;
    setFieldValue('customButtons', updatedButtons);
  };
  

  return (
    <div className="msg-body-internal buttons-body">
      <h4>Buttons</h4>
      <p className='first-p'>Create buttons that let customers respond to your message or take action.</p>
      <p className='second-p'>If you add more than three buttons, they will appear in a list.</p>
      <Button
        variant="outlined"
        aria-controls={anchorEl ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={anchorEl ? 'true' : undefined}
        onClick={handleClick}
      >
        + Add button <ImportExportOutlinedIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Paper sx={{ width: 320 }}>
          <MenuList>
          <p className='buttonsTextHeader'>Quick reply buttons</p>
            <MenuItem onClick={() => handleMenuItemClick('QUICK_REPLY')}>
              <ListItemIcon className='buttonsListItemIcon'><ReplyIcon fontSize='10px'/></ListItemIcon>
              <div className='buttonsText_parent'>
              <ListItemText>Custom replies</ListItemText>
              </div>
            </MenuItem>
            <Divider />
            <p className='buttonsTextHeader'>Call to action buttons</p>
            <MenuItem onClick={() => handleMenuItemClick('URL')} disabled={values.customButtons?.[1]?.bodies?.filter((body)=> body?.type === 'URL')?.length >= 2}>
                <ListItemIcon className='buttonsListItemIcon'><LaunchIcon fontSize='10px'/></ListItemIcon>
                <div className='buttonsText_parent'>
                <ListItemText>URL</ListItemText>
                <p className='buttonsText'>2 buttons maximum</p>
              </div>
            </MenuItem>
            <MenuItem onClick={() => handleMenuItemClick('PHONE_NUMBER')} disabled={values.customButtons?.[1]?.bodies?.some(body => body?.type === 'PHONE_NUMBER')}>
              <ListItemIcon className='buttonsListItemIcon'><PhoneIcon fontSize='10px' /></ListItemIcon>
              <div className='buttonsText_parent'>
                <ListItemText>Phone</ListItemText>
                <p className='buttonsText'>1 buttons maximum</p>
              </div>
            </MenuItem>
            {/* <MenuItem onClick={() => handleMenuItemClick('COUPON_CODE')} disabled={values.customButtons?.[1]?.bodies?.some(body => body?.type === 'COUPON_CODE')}>
              <ListItemIcon className='buttonsListItemIcon'><DescriptionIcon fontSize='10px'/></ListItemIcon>
              <div className='buttonsText_parent'>
                <ListItemText>Coupon code</ListItemText>
                <p className='buttonsText'>1 buttons maximum</p>
              </div>
            </MenuItem> */}
          </MenuList>
        </Paper>
      </Menu>

      <FieldArray name="customButtons">
        {() => (
          <DragDropContext onDragEnd={onDragEnd}>
            {values.customButtons.map((button, buttonIndex) => (
              button.bodies.length > 0 && (
                <Droppable key={buttonIndex} droppableId={String(buttonIndex)} direction="vertical">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      <div className="custom-btn-internal">
                        <div className="flex-center">
                          <span><ImportExportOutlinedIcon /></span>
                          <h3>{button.option === 'Custom Replies' ? "Quick reply" : "Call to action"}</h3>
                        </div>
                        {button.bodies.map((item, bodyIndex) => (
                          <Draggable key={item.id} draggableId={String(item.id)} index={bodyIndex}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="custom-internal-body"
                                style={{
                                    marginBottom: '10px',
                                    ...provided.draggableProps.style,
                                  }}
                              >
                                <div className="custom-del">
                                <div style={{width:'100%'}}>
                                <InputLabel>{item?.type === 'QUICK_REPLY' ? "Enter custom button text" : "Button Text"}</InputLabel>
                                  <TextField
                                    fullWidth
                                    name='text'
                                    placeholder={item?.type === 'QUICK_REPLY' ? 'e.g. Yes' : item?.type === 'PHONE_NUMBER' ?'e.g. Call us' : item?.type === 'URL' ? 'e.g. Visit website' : ''}
                                    value={item.text}
                                    error={errors?.customButtons?.[buttonIndex]?.bodies[bodyIndex]?.text}
                                    helperText={errors?.customButtons?.[buttonIndex]?.bodies[bodyIndex]?.text}
                                    onChange={(e) => handleTextFieldChange(button.option, item.id,'text', e.target.value)}
                                  />
                                  </div>
                                  {item.type === 'URL' &&
                                  <div style={{width:'100%'}}>
                                  <InputLabel>Website URL</InputLabel>
                                  <TextField
                                    fullWidth
                                    name='urlText'
                                    placeholder='http://www.example.com'
                                    value={item.url}
                                    error={errors?.customButtons?.[buttonIndex]?.bodies[bodyIndex]?.url}
                                    helperText={errors?.customButtons?.[buttonIndex]?.bodies[bodyIndex]?.url}
                                    onChange={(e) => handleTextFieldChange(button.option, item.id, 'url', e.target.value)}
                                  />
                                  </div>
                                  }
                                  {item.type === 'PHONE_NUMBER' &&
                                  <div style={{width:'100%'}}>
                                  <InputLabel>Phone Number</InputLabel>
                                  {/* <TextField
                                    fullWidth
                                    type='number'
                                    name='phoneNumber'
                                    placeholder='+917999988888'
                                    value={item.phone_number}
                                    error={errors?.customButtons?.[buttonIndex]?.bodies[bodyIndex]?.phone_number}
                                    helperText={errors?.customButtons?.[buttonIndex]?.bodies[bodyIndex]?.phone_number}
                                    onChange={(e) => handleTextFieldChange(button.option, item.id, 'phone_number', e.target.value)}
                                  /> */}
                                    <div key={buttonIndex}>
                                      <Field
                                        name='phoneNumber'
                                        component={PhoneNumberInput}
                                        label="Phone Number"
                                        defaultCountry="IN"
                                        style={{height:'100%',borderRadius:'50%'}}
                                        countryCallingCodeEditable={false}
                                        error={errors?.customButtons?.[buttonIndex]?.bodies[bodyIndex]?.phone_number}
                                        value={item.phone_number}
                                        handleTextFieldChange={handleTextFieldChange}
                                        button={button}
                                        item={item}
                                      />
                                       {/* <ErrorMessage name={`ctaButtons[${index}].value`} component="div" /> */}
                                    </div>
                                  </div>
                                  }
                                  <span
                                    className="drag-handle"
                                    onClick={() => handleDeleteBody(button.option, item.id)}
                                  >
                                    <DeleteTwoToneIcon />
                                  </span>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    </div>
                  )}
                </Droppable>
              )
            ))}
          </DragDropContext>
        )}
      </FieldArray>
    </div>
  );
};

export default WhatsAppTemplateButtons;
