import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { TableNoData } from 'src/components/table';

const ApexChart = ({ isConv, isBar, isWaBar, chartdata }) => {
  // Define the initial state for the line chart
  const [lineChartData, setLineChartData] = useState({
    series: [],
    options: {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
      },
      title: {
        text: '',
        align: 'left',
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'],
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: [], // Placeholder for dynamic dates
      },
    },
  });

  // Define the initial state for the first bar chart
  const [barChartData, setBarChartData] = useState({
    series: [
      {
        name: 'Messages',
        data: [], // Placeholder for message data
      },
    ],
    options: {
      chart: {
        type: 'bar',
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      title: {
        text: '',
        align: 'left',
      },
      xaxis: {
        categories: [], // Placeholder for message categories
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + ' messages';
          },
        },
      },
    },
  });

  // Define the initial state for the second bar chart
  const [whatsAppBarChartData, setWhatsAppBarChartData] = useState({
    series: [
      {
        name: 'WhatsApp Template',
        data: [], // Placeholder for WhatsApp Template messages
      },
      {
        name: 'WhatsApp Session',
        data: [], // Placeholder for WhatsApp Session messages
      },
    ],
    options: {
      chart: {
        type: 'bar',
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      title: {
        text: '',
        align: 'left',
      },
      xaxis: {
        categories: [], // Placeholder for dates
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + ' messages';
          },
        },
      },
    },
  });

  const conversationsData = [
    {
      conversation: '121325536',
      analysis: [
        { date: '27-july-2024', count: 5 },
        { date: '28-july-2024', count: 9 },
      ],
    },
    {
      conversation: '2422351325536',
      analysis: [
        { date: '27-july-2024', count: 6 },
        { date: '28-july-2024', count: 8 },
      ],
    },
  ];

  // Message status data
  const messageData = {
    sent: 50,
    delivered: 40,
    read: 28,
    failed: 10,
  };

  // WhatsApp Template vs Session Messages data
  const whatsAppData = [
    {
      date: '27-july-2024',
      report: {
        whatsappTemplate: 10,
        whatsappSession: 25,
      },
    },
    {
      date: '29-july-2024',
      report: {
        whatsappTemplate: 11,
        whatsappSession: 26,
      },
    },
  ];

  useEffect(() => {
    if (chartdata && chartdata?.length && chartdata[0]?.analysis) {
      // console.warn('No chart data available.');
    
      const dates = isConv
      ? conversationsData[0]?.analysis?.map((item) => item?.date)
      : chartdata[0]?.analysis?.map((item) => item?.date);

    const seriesData = isConv
      ? conversationsData?.map((bot) => ({
          name: bot?.conversation,
          data: bot?.analysis?.map((entry) => entry?.count),
        }))
      : chartdata?.map((bot) => ({
          name: bot?.botname,
          data: bot?.analysis?.map((entry) => entry?.count),
        }));

    // Update the line chart data
    setLineChartData((prevData) => ({
      ...prevData,
      series: seriesData,
      options: {
        ...prevData.options,
        xaxis: {
          ...prevData.options.xaxis,
          categories: dates,
        },
      },
    }));
    }
    else{
      setLineChartData((prevData) => ({
        ...prevData,
        series: [],
        options: {
          ...prevData.options,
          title: { text: 'No Data Available' },
        },
      }));
    }

    const categories = Object.keys(messageData);
    const seriesMessageData = Object.values(messageData);

    setBarChartData((prevData) => ({
      ...prevData,
      series: [
        {
          ...prevData.series[0],
          data: seriesMessageData,
        },
      ],
      options: {
        ...prevData.options,
        xaxis: {
          ...prevData.options.xaxis,
          categories: categories,
        },
      },
    }));

    // Update the WhatsApp bar chart data
    const whatsappDates = whatsAppData?.map((item) => item.date);
    const whatsappTemplateData = whatsAppData?.map((item) => item.report.whatsappTemplate);
    const whatsappSessionData = whatsAppData?.map((item) => item.report.whatsappSession);

    setWhatsAppBarChartData((prevData) => ({
      ...prevData,
      series: [
        {
          name: 'WhatsApp Template',
          data: whatsappTemplateData,
        },
        {
          name: 'WhatsApp Session',
          data: whatsappSessionData,
        },
      ],
      options: {
        ...prevData.options,
        xaxis: {
          ...prevData.options.xaxis,
          categories: whatsappDates,
        },
      },
    }));
  }, []);

  return (
    <div style={{ display: 'grid', justifyContent: 'center' }}>
      {isBar ? (
        barChartData.series.length === 0 ? (
          <TableNoData
            isNotFound={barChartData.series.length===0}
            titleText={`No Failed message found`}
            description={`You have no data`}
          />
        ) : (
          <div id="bar-chart">
            <ReactApexChart options={barChartData.options} series={barChartData.series} type="bar" height={350} />
          </div>
        )
      ) : isWaBar ? (whatsAppBarChartData.series.length===0?
        ( <TableNoData
         isNotFound={whatsAppBarChartData.series.length===0}
         titleText={`No Failed message found`}
         description={`You have no data`}
       />):(
        <div id="whatsapp-bar-chart">
          <ReactApexChart
            options={whatsAppBarChartData.options}
            series={whatsAppBarChartData.series}
            type="bar"
            height={350}
          />
        </div>
      ) ): (lineChartData?.series?.length===0 ?
        ( <TableNoData
         isNotFound={lineChartData?.series?.length===0}
         titleText={`No Failed message found`}
         description={`You have no data`}
       />):(
        <div id="line-chart">
          <ReactApexChart options={lineChartData.options} series={lineChartData.series} type="line" height={350} />
        </div>)
      )}
    </div>
  );
};

export default ApexChart;
