import { Box, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import LoadingScreen from 'src/components/LoadingScreen';

const WorkFlow = () => {
  const [loading, setLoading] = useState(true);
  const userData = useSelector((state) => state?.auth?.user?.data);
  const organizationId = userData?.user?.organization?.id;

  const IframeStyle = styled('iframe')(({ theme }) => ({
    border: 'none',
    height: '100%',
    width: '100%'
  }));

  useEffect(() => {
    const iframe = document.getElementById('workflow-iframe');
    const onLoadHandler = () => {
      setLoading(false);
    };

    if (iframe) {
      iframe.addEventListener('load', onLoadHandler);
    }

    return () => {
      if (iframe) {
        iframe.removeEventListener('load', onLoadHandler);
      }
    };
  }, []);

  return (
    <Box height={'100vh'} width={'100%'} position="relative">
      {loading && (
        <Box
          position="absolute"
          top="50%"
          left="50%"
          sx={{ transform: 'translate(-50%, -50%)' }}
        >
          <LoadingScreen />
        </Box>
      )}
      <IframeStyle
        id="workflow-iframe"
        src={`https://workflow-bot.odioiq.com/${organizationId}`}
      ></IframeStyle>
    </Box>
  );
};

export default WorkFlow;
