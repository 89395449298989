import { paramCase } from 'change-case';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Box,
  Tab,
  Tabs,
  Card,
  Table,
  Switch,
  Button,
  Tooltip,
  Divider,
  TableBody,
  Container,
  IconButton,
  TableContainer,
  TablePagination,
  FormControlLabel,
  Drawer,
  CircularProgress,
} from '@mui/material';
import { PATH_APP } from '../../routes/paths';
import useTabs from '../../hooks/useTabs';
import useSettings from '../../hooks/useSettings';
import useTable, { getComparator, emptyRows } from '../../hooks/useTable';
import { _userList } from '../../_mock';
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { TableEmptyRows, TableHeadCustom, TableNoData, TableSelectedActions } from '../../components/table';
import { ApiEndpoints } from '../../config';
import { useSnackbar } from 'notistack';
import axios from '../../utils/axios';
import AddNewWebhook from 'src/sections/@dashboard/webhook&api/AddNewWebhook';
import AddNewAPI from 'src/sections/@dashboard/webhook&api/AddNewApi';
import AddNewIFrame from 'src/sections/@dashboard/webhook&api/AddNewIFrame';
import WebhookAPITableToolbar from 'src/sections/@dashboard/webhook&api/list/WebhookAPITableToolbar';
import TableSkeleton from 'src/components/skeleton/TableSkeleton';
import WebhookTableRow from 'src/sections/@dashboard/webhook&api/list/WebhookTableRow';

const STATUS_OPTIONS = ['all', 'active', 'banned'];

const ROLE_OPTIONS = ['all'];

const TABLE_HEAD = [
  { id: 'id', label: 'ID', align: 'center' },
  { id: 'name', label: 'Name', align: 'center' },
  { id: 'channel_type', label: 'URL', align: 'center' },
  { id: 'agent', label: 'Type', align: 'center' },
  { id: 'auditor', label: 'Last Successful Call', align: 'center' },
  { id: 'action', label: 'Action', align: 'center' },
];

export default function Departments() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { themeStretch } = useSettings();

  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const STATUS_OPTIONS = ['all', 'api', 'webhook', 'iframe'];

  const [filterName, setFilterName] = useState('');
  const [filterRole, setFilterRole] = useState('all');
  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs('all');
  const [loading, setLoading] = useState(true);
  const [departments, setDepartments] = useState([]);

  const [webhookdrawerOpen, setWebhookDrawerOpen] = useState(false);
  const [apidrawerOpen, setApiDrawerOpen] = useState(false);
  const [iframedrawerOpen, setIFrameDrawerOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  const toggleWebhookDrawer = () => {
    setCurrentItem();
    setWebhookDrawerOpen(!webhookdrawerOpen);
  };

  const toggleApiDrawer = () => {
    setCurrentItem();
    setApiDrawerOpen(!apidrawerOpen);
  };

  const toggleIFrameDrawer = () => {
    setCurrentItem();
    setIFrameDrawerOpen(!iframedrawerOpen);
  };

  const handleFilterName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleFilterRole = (event) => {
    setFilterRole(event.target.value);
  };

  const handleDeleteRow = async (id) => {
    await axios.delete(ApiEndpoints.WEBHOOKS + '/' + id).then((res) => {
      try {
        if (res.data.status === 200) {
          enqueueSnackbar(res.data.message);
          getWebhooks();
        }
      } catch (error) {
        console.log(error);
      }
    });
  };

  const handleDeleteRows = (selected) => {
    const deleteRows = departments.filter((row) => !selected.includes(row.id));
    setSelected([]);
    setDepartments(deleteRows);
  };

  const handleEditRow = (item) => {
    setCurrentItem(item);
    if (item.agent === 'WEBHOOK') {
      setWebhookDrawerOpen(!webhookdrawerOpen);
    } else if (item.agent === 'API') {
      setApiDrawerOpen(!apidrawerOpen);
    } else if (item.agent === 'IFRAME') {
      setIFrameDrawerOpen(!iframedrawerOpen);
    }
  };

  const dataFiltered = applySortFilter({
    departments,
    comparator: getComparator(order, orderBy),
    filterName,
    filterRole,
    filterStatus,
  });

  const denseHeight = dense ? 52 : 72;

  const isNotFound =
    (!dataFiltered.length && !!filterName) ||
    (!dataFiltered.length && !!filterRole) ||
    (!dataFiltered.length && !!filterStatus);

  useEffect(() => {
    getWebhooks();
  }, []);

  function transformDepartmentData(departments) {
    return departments.map((dept) => {
      return {
        id: dept.id,
        name: dept?.name,
        channel_type: dept.url,
        agent: dept.type,
        auditor: dept.last_successful_call || 'No Data',
      };
    });
  }

  const getWebhooks = async () => {
    try {
      const response = await axios.get(ApiEndpoints.WEBHOOKS);
      if (response?.data?.status == 200) {
        const tranfomedData = transformDepartmentData(response?.data?.data);
        setDepartments(tranfomedData);
        setLoading(false);
      }
    } catch (error) {
      console.error('Failed to fetch departments:', error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  const getStatusCount = (status) => {
    if (status === 'all') {
      return departments?.length;
    }
    return departments?.filter((item) => item.agent.toLowerCase() === status)?.length;
  };
  return (
    <>
      <Drawer anchor={'right'} open={webhookdrawerOpen} onClose={() => toggleWebhookDrawer()}>
        <AddNewWebhook isEdit={currentItem} toggleDrawer={toggleWebhookDrawer} getWebhooks={getWebhooks} />
      </Drawer>
      <Drawer anchor={'right'} open={apidrawerOpen} onClose={() => toggleApiDrawer()}>
        <AddNewAPI isEdit={currentItem} toggleDrawer={toggleApiDrawer} getWebhooks={getWebhooks} />
      </Drawer>
      <Drawer anchor={'right'} open={iframedrawerOpen} onClose={() => toggleIFrameDrawer()}>
        <AddNewIFrame isEdit={currentItem} toggleDrawer={toggleIFrameDrawer} getWebhooks={getWebhooks} />
      </Drawer>
      <Page title="Webhook & API: List" style={{ marginLeft: '20px', marginRight: '20px' }}>
        {/* <Container maxWidth={themeStretch ? false : 'lg'}> */}
        <HeaderBreadcrumbs
          heading="My Webhook List"
          links={[
            { name: 'Dashboard', href: PATH_APP.general.dashboard },
            { name: 'Webhook & API', href: PATH_APP.general.departments },
          ]}
          action={
            <Box>
              <Button
                sx={{ mr: 1 }}
                variant="contained"
                onClick={() => toggleWebhookDrawer()}
                startIcon={<Iconify icon={'eva:plus-fill'} />}
              >
                New Webhook
              </Button>
              <Button
                sx={{ mr: 1 }}
                variant="contained"
                onClick={() => toggleApiDrawer()}
                startIcon={<Iconify icon={'eva:plus-fill'} />}
              >
                New API
              </Button>
              <Button
                variant="contained"
                onClick={() => toggleIFrameDrawer()}
                startIcon={<Iconify icon={'eva:plus-fill'} />}
              >
                New IFrame
              </Button>
            </Box>
          }
        />

        <Card>
          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={filterStatus}
            onChange={onChangeFilterStatus}
            sx={{ px: 2, bgcolor: 'background.neutral' }}
          >
            {STATUS_OPTIONS.map((tab) => (
              <Tab disableRipple key={tab} label={tab + `(${getStatusCount(tab)})`} value={tab} />
            ))}
          </Tabs>

          <Divider />

          <WebhookAPITableToolbar
            filterName={filterName}
            filterRole={filterRole}
            onFilterName={handleFilterName}
            onFilterRole={handleFilterRole}
            optionsRole={ROLE_OPTIONS}
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              {selected.length > 0 && (
                <TableSelectedActions
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={departments.length}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      departments.map((row) => row.id)
                    )
                  }
                  actions={
                    <Tooltip title="Delete">
                      <IconButton color="primary" onClick={() => handleDeleteRows(selected)}>
                        <Iconify icon={'eva:trash-2-outline'} />
                      </IconButton>
                    </Tooltip>
                  }
                />
              )}

              <Table size="small">
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={departments.length}
                  numSelected={selected.length}
                  onSort={onSort}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      departments.map((row) => row.id)
                    )
                  }
                />

                <TableBody>
                  {loading ? (
                    <TableSkeleton rowsPerPage={rowsPerPage} TABLE_HEAD={TABLE_HEAD} />
                  ) : (
                    <>                      
                      {dataFiltered?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                        <WebhookTableRow
                          key={row.id}
                          row={row}
                          selected={selected.includes(row.id)}
                          onSelectRow={() => onSelectRow(row.id)}
                          onDeleteRow={() => handleDeleteRow(row.id)}
                          onEditRow={() => handleEditRow(row)}
                        />
                      ))}
                      

                      <TableEmptyRows
                        height={denseHeight}
                        emptyRows={emptyRows(page, rowsPerPage, departments.length)}
                      />

                      <TableNoData isNotFound={isNotFound} titleText={`Add new WebHook & Api`} description={`You have no WebHook & Api to view in ${filterStatus} tab`} />
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative', maxHeight: '54px' }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={dataFiltered.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />
          </Box>
        </Card>
        {/* </Container> */}
      </Page>
    </>
  );
}

// Helper function for filtering and sorting
function applySortFilter({ departments, comparator, filterName, filterStatus, filterRole }) {
  // const stabilizedThis = departments.map((el, index) => [el, index]);

  // stabilizedThis.sort((a, b) => {
  //     const order = comparator(a[0], b[0]);
  //     if (order !== 0) return order;
  //     return a[1] - b[1];
  // });

  // departments = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    departments = departments.filter((item) => item.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1);
  }

  if (filterStatus !== 'all') {
    departments = departments.filter((item) => item?.agent?.toLowerCase() === filterStatus);
  }

  if (filterRole !== 'all') {
    departments = departments.filter((item) => item.role === filterRole);
  }

  return departments;
}
