import { paramCase } from 'change-case';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Box,
  Tab,
  Tabs,
  Card,
  Table,
  Switch,
  Button,
  Tooltip,
  Divider,
  TableBody,
  Container,
  IconButton,
  TableContainer,
  TablePagination,
  FormControlLabel,
  Drawer,
  Link,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { PATH_APP } from '../../routes/paths';
import useTabs from '../../hooks/useTabs';
import useSettings from '../../hooks/useSettings';
import useTable, { getComparator, emptyRows } from '../../hooks/useTable';
// _mock_
import { _userList } from '../../_mock';
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { TableEmptyRows, TableHeadCustom, TableNoData, TableSelectedActions } from '../../components/table';
// sections
import { TemplateTableToolbar, TemplateTableRow } from '../../sections/@dashboard/template/list';
import { ApiEndpoints } from '../../config';
import axios from '../../utils/axios';
import AddNewTemplate from '../../sections/@dashboard/template/AddNewTemplate';
import { set } from 'lodash';
import ShowMessageContent from 'src/sections/@dashboard/template/ShowMessageContent';
import TableSkeleton from 'src/components/skeleton/TableSkeleton';

const STATUS_OPTIONS = ['all', 'active', 'banned'];

const ROLE_OPTIONS = ['all'];

const TABLE_HEAD = [
  { id: 'id', label: 'Template Id', align: 'center' },
  { id: 'name', label: 'Name', align: 'center' },
  { id: 'message_type', label: 'Message Type', align: 'center' },
  { id: 'hsm', label: 'HSM', align: 'center' },
  { id: 'template_content', label: 'Message Content', align: 'center' },
  { id: 'action', label: 'Action', align: 'center' },
];

// ----------------------------------------------------------------------

export default function Templates() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { themeStretch } = useSettings();

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [filterName, setFilterName] = useState('');

  const [filterRole, setFilterRole] = useState('all');

  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs('all');
  const [loading, setLoading] = useState(true);

  const [templates, setTemplates] = useState([]);

  const STATUS_OPTIONS = ['all'];

  function transformDepartmentData(departments) {
    return departments.map((dept) => {
      return {
        id: dept.id,
        name: dept?.name,
        message_type: dept?.message_type,
        hsm: 'HSM',
        message_content: dept?.template_content,
      };
    });
  }

  const handleFilterName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleFilterRole = (event) => {
    setFilterRole(event.target.value);
  };
  const handleDeleteRow = async (id) => {
    await axios.delete(ApiEndpoints.DELETETEMPLATE + '/' + id).then((res) => {
      try {
        if (res.data.status === 200) {
          enqueueSnackbar(res.data.message);
          getTemplates().then((res) => {
            const temps = res.data.data.template;
            setTemplates(transformDepartmentData(temps));
          });
        }
      } catch (error) {
        console.log(error);
      }
    });
  };

  const handleDeleteRows = (selected) => {
    const deleteRows = templates.filter((row) => !selected.includes(row.id));
    setSelected([]);
    setTemplates(deleteRows);
  };

  const [isEdit, setIsEdit] = useState();
  const [messageContent, setMessageContent] = useState();
  const [messageContentDrawer, setMessageContentDrawer] = useState(false);
  const handleEditRow = (id) => {
    //navigate(PATH_APP.user.edit(paramCase(id)));
    setDrawerOpen(id);
    setIsEdit(id);
  };
  const toggleMessageContentDrawer = () => {
    setMessageContentDrawer(!messageContentDrawer);
  };

  const dataFiltered = applySortFilter({
    templates,
    comparator: getComparator(order, orderBy),
    filterName,
    filterRole,
    filterStatus,
  });

  const denseHeight = dense ? 52 : 72;

  const isNotFound =
    (!dataFiltered.length && !!filterName) ||
    (!dataFiltered.length && !!filterRole) ||
    (!dataFiltered.length && !!filterStatus);

  const getTemplates = async () => {
    const templates = await axios.get(ApiEndpoints.GETTEMPLATES);
    return templates;
  };
  const fetchTemplates = async () => {
    getTemplates()
      .then((res) => {
        if (res?.data?.status === 200) {
          const temps = res.data.data.template;
          const tranfomedData = transformDepartmentData(temps);
          setTemplates(tranfomedData);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error('Failed to fetch auditors:', err);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchTemplates();
  }, []);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerOpen1, setDrawerOpen1] = useState();

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
    setIsEdit();
  };
  return (
    <>
      <Drawer anchor={'right'} open={drawerOpen} onClose={() => toggleDrawer()}>
        <AddNewTemplate isEdit={isEdit} toggleDrawer={toggleDrawer} fetchTemplates={fetchTemplates} />
      </Drawer>
      <Drawer anchor={'right'} open={messageContentDrawer} onClose={() => toggleMessageContentDrawer()}>
        <ShowMessageContent isEdit={isEdit} toggleDrawer={toggleMessageContentDrawer} messageContent={messageContent} />
      </Drawer>
      <Page title="Template: List" style={{ marginLeft: '20px', marginRight: '20px' }}>
        {/* <Container maxWidth={themeStretch ? false : 'lg'}> */}
        <HeaderBreadcrumbs
          heading="My Template List"
          links={[
            { name: 'Dashboard', href: PATH_APP.general.dashboard },
            { name: 'Templates', href: PATH_APP.general.templates },
          ]}
          action={
            <Button variant="contained" onClick={() => toggleDrawer()} startIcon={<Iconify icon={'eva:plus-fill'} />}>
              New Template
            </Button>
          }
        />

        <Card>
          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={filterStatus}
            onChange={onChangeFilterStatus}
            sx={{ px: 2, bgcolor: 'background.neutral' }}
          >
            {STATUS_OPTIONS.map((tab) => (
              <Tab disableRipple key={tab} label={tab + `(${templates?.length})`} value={tab} />
            ))}
          </Tabs>

          <Divider />

          <TemplateTableToolbar
            filterName={filterName}
            filterRole={filterRole}
            onFilterName={handleFilterName}
            onFilterRole={handleFilterRole}
            optionsRole={ROLE_OPTIONS}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              {selected.length > 0 && (
                <TableSelectedActions
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={templates.length}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      templates.map((row) => row.id)
                    )
                  }
                  actions={
                    <Tooltip title="Delete">
                      <IconButton color="primary" onClick={() => handleDeleteRows(selected)}>
                        <Iconify icon={'eva:trash-2-outline'} />
                      </IconButton>
                    </Tooltip>
                  }
                />
              )}

              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={templates.length}
                  numSelected={selected.length}
                  onSort={onSort}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      templates.map((row) => row.id)
                    )
                  }
                />

                <TableBody>
                  {loading ? (
                    <TableSkeleton rowsPerPage={rowsPerPage} TABLE_HEAD={TABLE_HEAD} />
                  ) : (
                    <>
                      {dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                        <TemplateTableRow
                          key={row.id}
                          row={row}
                          selected={selected.includes(row.id)}
                          onSelectRow={() => onSelectRow(row.id)}
                          onDeleteRow={() => handleDeleteRow(row.id)}
                          onEditRow={() => handleEditRow(row)}
                          onMessageContent={() => {
                            setMessageContent(row);
                            toggleMessageContentDrawer();
                          }}
                        />
                      ))}
                      <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, templates.length)} />

                      <TableNoData isNotFound={isNotFound} titleText={`Add new templates`} description={`You have no templates to view`} />
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative', maxHeight: '54px' }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={dataFiltered.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />
          </Box>
        </Card>
        {/* </Container> */}
      </Page>
    </>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({ templates, comparator, filterName, filterStatus, filterRole }) {
  // const stabilizedThis = templates.map((el, index) => [el, index]);

  // stabilizedThis.sort((a, b) => {
  //     const order = comparator(a[0], b[0]);
  //     if (order !== 0) return order;
  //     return a[1] - b[1];
  // });

  // templates = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    templates = templates.filter((item) => item.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1);
  }

  if (filterStatus !== 'all') {
    templates = templates.filter((item) => item.status === filterStatus);
  }

  if (filterRole !== 'all') {
    templates = templates.filter((item) => item.role === filterRole);
  }

  return templates;
}
