import React from 'react';
import {
  Button,
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Switch,
  Typography,
  Modal,
  TextField,
  InputAdornment,
  CircularProgress,
} from '@mui/material';
import './whatsapptemplate.css';
import { PATH_APP } from 'src/routes/paths';
import { InfoOutlined, SearchOffOutlined } from '@mui/icons-material';
import { ReturnParsedValue } from './WhatsAppTemplateHome_Library';
import { Reply as ReplyIcon, Launch as LaunchIcon, Phone as PhoneIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router';
const style = {
  position: 'absolute',
  top: '35%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: 'background.paper',
  boxShadow: 24,
  paddingTop: 2,
  paddingRight: 2,
  paddingLeft: 2,
  borderRadius: 1,
};
const WATemplatesMyTemplates = ({ handleOpen, handleClose, open, myTemplates, loader }) => {
  const navigate = useNavigate();
  return (
    <>
      {loader ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center',height:'50vh'}}>
          <CircularProgress />
        </Box>
      ) : (
        <div className="text-left">
          <section className="navigation-whatsapp">
            <div>
              <FormGroup>
                <FormControlLabel control={<Checkbox />} label="Show approved templates only" />
              </FormGroup>
            </div>
            <div className="ignore-sample-templates">
              <FormGroup>
                <FormControlLabel control={<Switch />} label="Ignore sample templates" sx={{ marginRight: '9px' }} />
              </FormGroup>
              <InfoOutlined fontSize="12px" />
            </div>
            <div className="whatsapp-input-text">
              <div className="input__group" data-group="true">
                <div className="input__left-element">
                  <svg
                    stroke="currentColor"
                    fill="none"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    strokeLinecap="round"
                    stroke-linejoin="round"
                    focusable="false"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                  </svg>
                </div>
                <input
                  type="text"
                  placeholder="Search templates"
                  data-cy="undefined-search-box"
                  className="odio-input"
                  value=""
                />
              </div>
            </div>
            <div className="whatsapp-input-text">
              <div className="input__group" data-group="true">
                <div className="input__left-element">
                  <svg
                    stroke="#22c35e"
                    fill="#22c35e"
                    strokeWidth="0"
                    viewBox="0 0 512 512"
                    focusable="false"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M260.062 32C138.605 32 40.134 129.701 40.134 250.232c0 41.23 11.532 79.79 31.559 112.687L32 480l121.764-38.682c31.508 17.285 67.745 27.146 106.298 27.146C381.535 468.464 480 370.749 480 250.232 480 129.701 381.535 32 260.062 32zm109.362 301.11c-5.174 12.827-28.574 24.533-38.899 25.072-10.314.547-10.608 7.994-66.84-16.434-56.225-24.434-90.052-83.844-92.719-87.67-2.669-3.812-21.78-31.047-20.749-58.455 1.038-27.413 16.047-40.346 21.404-45.725 5.351-5.387 11.486-6.352 15.232-6.413 4.428-.072 7.296-.132 10.573-.011 3.274.124 8.192-.685 12.45 10.639 4.256 11.323 14.443 39.153 15.746 41.989 1.302 2.839 2.108 6.126.102 9.771-2.012 3.653-3.042 5.935-5.961 9.083-2.935 3.148-6.174 7.042-8.792 9.449-2.92 2.665-5.97 5.572-2.9 11.269 3.068 5.693 13.653 24.356 29.779 39.736 20.725 19.771 38.598 26.329 44.098 29.317 5.515 3.004 8.806 2.67 12.226-.929 3.404-3.599 14.639-15.746 18.596-21.169 3.955-5.438 7.661-4.373 12.742-2.329 5.078 2.052 32.157 16.556 37.673 19.551 5.51 2.989 9.193 4.529 10.51 6.9 1.317 2.38.901 13.531-4.271 26.359z"></path>
                  </svg>
                </div>
                <input
                  type="text"
                  placeholder="Demo number"
                  data-cy="undefined-search-box"
                  className="odio-input"
                  value=""
                />
              </div>
            </div>
            <div className="whatsapp-button__group" data-orientation="horizontal">
              <button type="button" className="whatsapp-button" data-active="">
                <span className="whatsapp-button__icon">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 16 16"
                    color="gray.500"
                    aria-hidden="true"
                    focusable="false"
                    height="20"
                    width="20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                    ></path>
                  </svg>
                </span>
                List
              </button>
              <button type="button" className="whatsapp-button">
                <span className="whatsapp-button__icon">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 16 16"
                    color="gray.500"
                    aria-hidden="true"
                    focusable="false"
                    height="20"
                    width="20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z"></path>
                  </svg>
                </span>
                Grid
              </button>
            </div>
          </section>
          <section className="crt-whatspp-temp">
            <div className="four-grid">
              <div className="first-box">
                <div className="box-header">
                  <p className="box-paragraph">Create new template</p>
                </div>
                <div className="box-stack">
                  <button
                    onClick={() => navigate(PATH_APP.management.waTemplatesNew)}
                    type="button"
                    className="box-button"
                    aria-label="create-new-template"
                  >
                    <svg viewBox="0 0 24 24" focusable="false" aria-hidden="true">
                      <path
                        fill="currentColor"
                        d="M0,12a1.5,1.5,0,0,0,1.5,1.5h8.75a.25.25,0,0,1,.25.25V22.5a1.5,1.5,0,0,0,3,0V13.75a.25.25,0,0,1,.25-.25H22.5a1.5,1.5,0,0,0,0-3H13.75a.25.25,0,0,1-.25-.25V1.5a1.5,1.5,0,0,0-3,0v8.75a.25.25,0,0,1-.25.25H1.5A1.5,1.5,0,0,0,0,12Z"
                      ></path>
                    </svg>
                  </button>
                </div>
                <button type="button" class="box-x-button" onClick={handleOpen}>
                  Create new from our library →
                </button>
                {open && (
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <Typography id="modal-modal-title" variant="h6" component="h2">
                        Build a Bot
                        <span>
                          <a href="#" className="bot-modal">
                            Learn more
                          </a>
                        </span>
                        <span>
                          <svg
                            stroke="#3182ce"
                            fill="#3182ce"
                            strokeWidth="0"
                            viewBox="0 0 24 24"
                            focusable="false"
                            aria-hidden="true"
                            height="1em"
                            width="14px"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path fill="none" d="M0 0h24v24H0z"></path>
                            <path d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"></path>
                          </svg>
                        </span>
                      </Typography>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        How would you like to build your bot?
                      </Typography>
                      <Typography id="modal-modal-boxes" sx={{ mt: 2 }}>
                        <div className="box-modal">
                          <div className="box-onee">
                            <svg
                              stroke="currentColor"
                              fill="none"
                              strokeWidth="2"
                              viewBox="0 0 24 24"
                              strokeLinecap="round"
                              stroke-linejoin="round"
                              focusable="false"
                              class="chakra-icon css-6ey7w3"
                              height="1.5rem"
                              width="1.5rem"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="m21.64 3.64-1.28-1.28a1.21 1.21 0 0 0-1.72 0L2.36 18.64a1.21 1.21 0 0 0 0 1.72l1.28 1.28a1.2 1.2 0 0 0 1.72 0L21.64 5.36a1.2 1.2 0 0 0 0-1.72Z"></path>
                              <path d="m14 7 3 3"></path>
                              <path d="M5 6v4"></path>
                              <path d="M19 14v4"></path>
                              <path d="M10 2v2"></path>
                              <path d="M7 8H3"></path>
                              <path d="M21 16h-4"></path>
                              <path d="M11 3H9"></path>
                            </svg>
                            <p className="modal-first-p">Build with AI</p>
                            <p className="modal-second-p">
                              Got a use-case? Just tell us what you need, and we'll make it happen with one click!
                            </p>
                          </div>
                          <div className="box-onee">
                            <svg
                              stroke="currentColor"
                              fill="none"
                              strokeWidth="2"
                              viewBox="0 0 24 24"
                              strokeLinecap="round"
                              stroke-linejoin="round"
                              focusable="false"
                              height="1.5rem"
                              width="1.5rem"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect width="18" height="7" x="3" y="3" rx="1"></rect>
                              <rect width="9" height="7" x="3" y="14" rx="1"></rect>
                              <rect width="5" height="7" x="16" y="14" rx="1"></rect>
                            </svg>
                            <p className="modal-first-p">Use from template</p>
                            <p className="modal-second-p">
                              Choose from a variety of pre-designed templates to jumpstart!
                            </p>
                          </div>
                          <div className="box-onee">
                            <svg
                              stroke="currentColor"
                              fill="none"
                              strokeWidth="2"
                              viewBox="0 0 24 24"
                              strokeLinecap="round"
                              stroke-linejoin="round"
                              focusable="false"
                              height="1.5rem"
                              width="1.5rem"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M18.37 2.63 14 7l-1.59-1.59a2 2 0 0 0-2.82 0L8 7l9 9 1.59-1.59a2 2 0 0 0 0-2.82L17 10l4.37-4.37a2.12 2.12 0 1 0-3-3Z"></path>
                              <path d="M9 8c-2 3-4 3.5-7 4l8 10c2-1 6-5 6-7"></path>
                              <path d="M14.5 17.5 4.5 15"></path>
                            </svg>
                            <p className="modal-first-p">Start from scratch</p>
                            <p className="modal-second-p">
                              Begin your journey—build from the ground up with our intuitive drag-and-drop chatbot
                              builder.
                            </p>
                          </div>
                        </div>
                        <footer className="footer-modal">
                          <Button type="button">Cancel</Button>
                          <Button variant="contained">Start Building</Button>
                        </footer>
                      </Typography>
                    </Box>
                  </Modal>
                )}
              </div>
              {myTemplates
                ?.filter((item) => item?.status !== 'REJECTED')
                ?.map((item) => (
                  <div className="second-box" key={item?.id}>
                    <div className="second-box-header">
                      <div className="first-div">
                        <p className="basic-para">{item?.name}</p>
                        <div className="basic-div">
                          <span className="basic-div-child">
                            <p className="box-para">{item?.status}</p>
                          </span>
                          <p className="box-para-one">{item?.category}</p>
                        </div>
                      </div>
                    </div>

                    <div className="text-body">
                      <div className="body-parent">
                        <div className="card-body">
                          <h4>{item?.components?.find((comp) => comp?.type === 'HEADER')?.text}</h4>
                          {item?.components?.find((comp) => comp?.type === 'HEADER')?.format === 'IMAGE' && (
                            <div>
                              <img
                                src={
                                  item?.components?.find((comp) => comp?.type === 'HEADER')?.media_support_header
                                    ?.s3_url
                                }
                                alt="Preview unavailable"
                                style={{ maxWidth: '100%' }}
                              />
                            </div>
                          )}

                          {item?.components?.find((comp) => comp?.type === 'HEADER')?.format === 'VIDEO' && (
                            <div>
                              <video controls style={{ maxWidth: '100%' }}>
                                <source
                                  src={
                                    item?.components?.find((comp) => comp?.type === 'HEADER')?.media_support_header
                                      ?.s3_url
                                  }
                                  type="video/mp4"
                                />
                                Your browser does not support the video tag.
                              </video>
                            </div>
                          )}
                          <p>
                            {ReturnParsedValue(
                              item?.components?.find((comp) => comp?.type === 'BODY')?.text,
                              item?.components?.find((comp) => comp?.type === 'BODY')?.example?.body_text?.[0]
                            )}
                          </p>
                          <p className="experience-p">
                            {item?.components?.find((comp) => comp?.type === 'FOOTER')?.text}
                          </p>

                          {/* Uncommented and improved the button rendering logic */}
                          {item?.components?.find((comp) => comp?.type === 'BUTTONS')?.buttons && (
                            <div className="action">
                              {item?.components
                                ?.find((comp) => comp?.type === 'BUTTONS')
                                ?.buttons?.map((button, index) => (
                                  <p key={index}>
                                    <span>
                                      {button?.type === 'QUICK_REPLY' ? (
                                        <ReplyIcon fontSize="12px" />
                                      ) : button?.type === 'URL' ? (
                                        <LaunchIcon fontSize="12px" />
                                      ) : button?.type === 'PHONE_NUMBER' ? (
                                        <PhoneIcon fontSize="12px" />
                                      ) : null}
                                    </span>
                                    {button?.text}
                                  </p>
                                ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="body-footer">
                      <span className="body-en">{item?.language}</span>
                    </div>
                  </div>
                ))}
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default WATemplatesMyTemplates;
