import React, { useEffect, useState } from 'react';
import { Button, Grid, InputLabel } from '@mui/material';
import './Whatsapp.css';
import axiosInstance from 'src/utils/axios';
import { ApiEndpoints } from 'src/config';
import { useLocation, useNavigate } from 'react-router';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import WhatsAppTemplateButtons from './WhatsAppTemplateButtons';
import WhatsAppTemplateHeader from './WhatsAppTemplateHeader';
import WhatsAppTemplateBody from './WhatsAppTemplateBody';
import WhatsAppTemplateWA_Skeleton from './WhatsAppTemplateWA_Skeleton';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import WhatsAppTemplateSection_one from './WhatsAppTemplateSection_one';
import { useSnackbar } from 'notistack';
import LoadingScreen from 'src/components/LoadingScreen';
import qs from 'qs';
import { PATH_APP } from 'src/routes/paths';

export const CustomInputLabel = ({ children, htmlFor, required, ...otherProps }) => {
  return (
    <InputLabel
      shrink
      htmlFor={htmlFor}
      required={required}
      sx={{
        '& .MuiFormLabel-asterisk': {
          color: 'red',
        },
      }}
      {...otherProps}
    >
      {children}
    </InputLabel>
  );
};

const WhatsAppTemplates = () => {
  const [categoryData, setCategoryData] = useState([]);
  const [department, setDepartment] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();
  // Parse the query string from the URL
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const isEditCase = Object.keys(query)?.length > 0;
  const { enqueueSnackbar } = useSnackbar();

  const handleTransformData = (data) => {
    return data?.map((item, index) => {
      return item?.data?.template_name
        ? { label: item?.data?.template_name, value: item?.data?.id, categoryPayload: item?.data, ...item?.meta_data }
        : { label: item?.name, value: item?.id };
    });
  };

  const fetchWATemplatesCategory = async () => {
    await axiosInstance
      .get(ApiEndpoints.WA_TEMPLATES_MASTER_CATEGORY)
      .then((response) => {
        if (response?.data?.status === 200) {
          const tranformData = handleTransformData(response?.data?.data);
          setCategoryData(tranformData);
        }
      })
      .catch((err) => console.log('Api call Error : ', err));
  };

  const getDepartments = () => {
    axiosInstance
      .get(ApiEndpoints.DEPARTMENTS)
      .then((response) => {
        if (response?.data?.status === 200) {
          const tranformDepartmentData = handleTransformData(response?.data?.data);
          setDepartment(tranformDepartmentData);
        }
      })
      .catch((error) => {
        console.log(`error`, { error });
      });
  };

  useEffect(() => {
    fetchWATemplatesCategory();
    getDepartments();
  }, []);

  const transformTextToVariablesArrayHelper = (text, body_params) => {
    const placeholders = text.match(/{{\d+}}/g);
    const result = placeholders.map((placeholder, index) => {
      const name = placeholder.replace(/[{}]/g, ''); // Remove curly braces
      return {
        name: parseInt(name),
        sampleValue: body_params[index],
      };
    });
    return result;
  };
  const transformButtonsHelper = (buttons) => {
    const buttonsList = [
      { option: 'Custom Replies', bodies: [] },
      { option: 'Call to action', bodies: [] },
    ];
    buttons?.forEach((button) => {
      if (button?.type === 'QUICK_REPLY') {
        buttonsList[0].bodies.push({ id: Date.now(), ...button });
      } else {
        buttonsList[1].bodies.push({ id: Date.now(), ...button });
      }
    });
    return buttonsList;
  };

  const initialValues = {
    templateName: isEditCase ? query?.name : '',
    templateCategory: isEditCase
      ? categoryData?.filter((item) => item?.categoryPayload?.template_type === query?.category)?.[1]
      : { label: '', value: '' },
    department: '',
    templateAllowCategoryChange: '',
    templateChannelNumber: '',
    headerText: isEditCase ? query?.header : '',
    messageHeaderType: isEditCase ? (query?.header ? 'TEXT' : '') : '',
    messageBodyTextArea: isEditCase ? query?.body : '',
    variables: isEditCase ? transformTextToVariablesArrayHelper(query?.body, query?.body_params) : [],
    messageBodyFooterText: isEditCase ? query?.footer : '',
    customButtons: isEditCase
      ? transformButtonsHelper(query?.buttons)
      : [
          { option: 'Custom Replies', bodies: [] },
          { option: 'Call to action', bodies: [] },
        ],
    mode: 'search',
  };

  const handleSubmit = (values) => {
    const components = [];
    const replaceWithIndexedBrackets = (str) => {
      let index = 1;
      return str?.replace(/{{.*?}}/g, () => `{{${index++}}}`);
    };
    if (values?.headerText || values?.uploadedDocumentResponse || values?.latitude || values?.longitude) {
      const headerComponent = {
        type: 'HEADER',
        format: values?.messageHeaderType,
      };
      if (values?.messageHeaderType === 'TEXT') {
        headerComponent.text = values?.headerText;
      } else if (values?.uploadedDocumentResponse) {
        headerComponent.example = {
          header_handle: values?.uploadedDocumentResponse?.wAMediaId,
        };
      }
      components.push(headerComponent);
    }
    if (values?.messageBodyTextArea) {
      const bodyComponent = {
        text: replaceWithIndexedBrackets(values?.messageBodyTextArea),
        type: 'BODY',
      };
      if (values?.variables?.length > 0) {
        const variables = values?.variables?.map((item) => item?.sampleValue);
        bodyComponent.example = { body_text: [variables] };
      }
      components.push(bodyComponent);
    }
    if (values?.messageBodyFooterText) {
      components.push({
        text: values?.messageBodyFooterText,
        type: 'FOOTER',
      });
    }
    if (values?.addedButtons) {
      const buttonsList = values?.addedButtons?.map(({ id, ...rest }) => rest);
      components.push({
        buttons: buttonsList,
        type: 'BUTTONS',
      });
    }
    const payload = {
      category: values?.templateCategory?.categoryPayload?.template_type,
      master_template: values?.templateCategory?.categoryPayload,
      components,
      language: 'en_US', // Hardcoded for now
      name: values?.templateName,
    };
    if (
      values?.messageHeaderType &&
      values?.uploadedDocumentResponse?.type &&
      ['IMAGE', 'VIDEO', 'DOCUMENT'].includes(values?.messageHeaderType)
    ) {
      const fileType = ['jpg', 'png', 'jpeg'].includes(values?.uploadedDocumentResponse?.type)
        ? 'image'
        : ['mp4', '3gpp'].includes(values?.uploadedDocumentResponse?.type)
        ? 'video'
        : 'document';
      payload.media = {
        type: fileType,
        s3_url: values?.uploadedDocumentResponse?.s3Url,
        mime: values?.uploadedDocumentResponse?.mime,
        length: values?.uploadedDocumentResponse?.length,
        handle_url: values?.uploadedDocumentResponse?.wAMediaId,
      };
    } if (values?.messageHeaderType === 'LOCATION') {
      payload.location = {
        longitude: values?.longitude,
        latitude: values?.latitude,
        name: values?.name,
        address: values?.address,
        }
    } 
    axiosInstance
      .post(`${ApiEndpoints.WA_TEMPLATE_CREATE}?departmentId=${values?.department?.value}`, payload)
      .then((response) => {
        debugger
        if (response?.data?.status === 200) {
          navigate(PATH_APP.management.waTemplates);
          enqueueSnackbar(response?.data?.message, { variant: 'success' });
        } else {
          enqueueSnackbar(response?.data?.message, { variant: 'error' });
        }
      })
      .catch((error) => {
        console.error(`error`, { error });
      });
  };
  const validationSchema = Yup.object().shape({
    templateName: Yup.string().required('Template Name is required'),
    templateCategory: Yup.object({
      value: Yup.string().required('Template category is required'),
    }).required('Template category is required'),
    department: Yup.object({
      value: Yup.string().required('Department is required'),
    }).required('Department is required'),
    templateAllowCategoryChange: Yup.object({
      value: Yup.string().required('Category change is required'),
    }).required('Category change is required'),
    headerText: Yup.string().nullable().max(60,"Header text cannot exceed 60 characters"),
    messageBodyTextArea: Yup.string().max(1024,"Body text cannot exceed 1024 characters"),
    messageBodyFooterText: Yup.string().max(60,"Footer text cannot exceed 60 characters"),
    customButtons: Yup.array().of(
      Yup.object().shape({
        bodies: Yup.array().of(
          Yup.object().shape({
            text: Yup.string()
              .max(25, 'Button text cannot exceed 25 characters'),
            url: Yup.string()
              .when('type', {
                is: 'URL',
                then: Yup.string()
                  .url('Must be a valid URL')
                  .max(2000, 'URL cannot exceed 2000 characters')
              }),
            phone_number: Yup.string()
              .when('type', {
                is: 'PHONE_NUMBER',
                then: Yup.string()
                  .max(20, 'Phone number cannot exceed 20 characters')
              }),
          })
        )
      })
    ),
  });
  return (
    <>
      {categoryData?.length > 0 && department?.length > 0 ? (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange={false}
          enableReinitialize
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({ values, setFieldValue, errors, touched, handleChange, validateField }) => {
            const templateComponents = values?.templateCategory?.requiredComponents || [];
            console.log('formValues_temp==>', { values, errors });
            return (
              <Form>
                <section className="main">
                  <section className="whatsapptemplates">
                    <div className="header">
                      <div className="flex-center">
                        <span onClick={() => navigate(-1)} style={{ cursor: 'pointer' }}>
                          <KeyboardBackspaceOutlinedIcon />
                        </span>
                        <h1>Create Template</h1>
                      </div>
                      <p>
                        <Button variant="contained" type="submit">
                          Submit for approval
                        </Button>
                      </p>
                    </div>
                  </section>

                  <section className="form-box">
                    <WhatsAppTemplateSection_one
                      categoryData={categoryData}
                      department={department}
                      values={values}
                      errors={errors}
                      validateField={validateField}
                      setFieldValue={setFieldValue}
                    />
                  </section>

                  {values?.templateCategory?.value && (
                    <section className="message-header">
                      <Grid container spacing={2}>
                        <Grid item xs={8}>
                          {templateComponents.map((component, index) => {
                            switch (component.type) {
                              case 'HEADER':
                                return component.mandatory ? (
                                  <WhatsAppTemplateHeader
                                    key={index}
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    errors={errors}
                                    touched={touched}
                                    handleChange={handleChange}
                                  />
                                ) : null;
                              case 'BODY':
                                return component.mandatory ? (
                                  <WhatsAppTemplateBody
                                    key={index}
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    errors={errors}
                                    isEditCase={isEditCase}
                                  />
                                ) : null;
                              case 'BUTTONS':
                                return component.mandatory ? (
                                  <WhatsAppTemplateButtons
                                    key={index}
                                    values={values}
                                    errors={errors}
                                    setFieldValue={setFieldValue}
                                  />
                                ) : null;
                              default:
                                return null;
                            }
                          })}
                        </Grid>

                        <Grid item xs={4}>
                          <WhatsAppTemplateWA_Skeleton values={values} setFieldValue={setFieldValue} />
                        </Grid>
                      </Grid>
                    </section>
                  )}
                </section>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <LoadingScreen />
      )}
    </>
  );
};

export default WhatsAppTemplates;
