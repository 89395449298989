import React from 'react';
import { CustomInputLabel } from './WhatsAppTemplates';
import { FormControl, FormHelperText, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import './Whatsapp.css';

const WhatsAppTemplateSection_one = ({ categoryData, values, setFieldValue,department,errors,validateField }) => {
  const options = [
    { label: 'Yes', value: 'yes' },
    { label: 'No', value: 'no' },
  ];

  return (
    <div>
      <div className="combo-box">
        <CustomInputLabel htmlFor="templateName" required>
          Template Name
        </CustomInputLabel>
        <FormControl fullWidth>
        <TextField
          fullWidth
          placeholder="welcome_template,orderconfirmation"
          id="fullWidth"
          name="templateName"
          sx={{ backgroundColor: '#fff', borderRadius: '10px', input: { height: '5px' } }}
          value={values?.templateName}
          onChange={(e) => {
            const inputValue = e.target.value
            .toLowerCase()
            .replace(/[^a-z0-9]+/g, '_');

          setFieldValue('templateName', inputValue);
          validateField('templateName');
          }}
          error={errors?.templateName}
        />
        <FormHelperText error={Boolean(errors?.templateName)}>{errors?.templateName}</FormHelperText>
        </FormControl>
      </div>
      <div className="dropdown1">
        <div>
          <CustomInputLabel shrink htmlFor="templateCategory" required>
            Category
          </CustomInputLabel>
          <FormControl fullWidth>
          <Autocomplete
            disablePortal
            name="templateCategory"
            id="combo-box-demo"
            options={categoryData}
            value={values?.templateCategory }
            onChange={(event, newValue) => setFieldValue('templateCategory', newValue)}
            getOptionLabel={(option) => option.label}
            sx={{ width: 356 }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select Category"
                sx={{ backgroundColor: '#fff', borderRadius: '10px', input: { height: '5px' } }}
                error={errors?.templateCategory?.value}
              />
            )}
          />
            <FormHelperText error={Boolean(errors?.templateCategory?.value)}>{errors?.templateCategory?.value}</FormHelperText>
          </FormControl>
        </div>
        <div>
          <CustomInputLabel shrink htmlFor="templateName" required>
            Allow Category Change
          </CustomInputLabel>
          <FormControl fullWidth>
          <Autocomplete
            name="templateAllowCategoryChange"
            id="controllable-states-demo"
            options={options}
            onChange={(event, newValue) => {
              setFieldValue('templateAllowCategoryChange', newValue);
            }}
            sx={{ width: 356 }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Allow category change"
                sx={{ backgroundColor: '#fff', borderRadius: '10px', input: { height: '5px' } }}
                error={errors?.templateAllowCategoryChange?.value}
              />
            )}
          />
          <FormHelperText error={Boolean(errors?.templateAllowCategoryChange?.value)}>{errors?.templateAllowCategoryChange?.value}</FormHelperText>
          </FormControl>
        </div>
        <div>
          <CustomInputLabel shrink htmlFor="department" required>
            Department
          </CustomInputLabel>
          <FormControl fullWidth>
          <Autocomplete
            name="department"
            id="controllable-states-demo"
            options={department}
            onChange={(event, newValue) => {
              setFieldValue('department', newValue);
            }}
            sx={{ width: 356 }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select Deparment"
                sx={{ backgroundColor: '#fff', borderRadius: '10px', input: { height: '5px' } }}
                error={errors?.department?.value}
              />
            )}
          />
          <FormHelperText error={Boolean(errors?.department?.value)}>{errors?.department?.value}</FormHelperText>
          </FormControl>
        </div>
      </div>
    </div>
  );
};

export default WhatsAppTemplateSection_one;
