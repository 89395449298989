import React, { useState } from 'react';
import {
  Button,
  TextField,
  Box,
  TextareaAutosize,
  Menu,
  MenuItem,
  Divider,
  FormControl,
  styled,
  Tooltip,
} from '@mui/material';
import './Whatsapp.css';
import { InfoOutlined } from '@mui/icons-material';
import { CustomInputLabel } from './WhatsAppTemplates';

const blue = {
  100: '#DAECFF',
  200: '#b6daff',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const TextareaCustom = styled(TextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  resize: vertical;
  min-height: 200px; 
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

const WhatsAppTemplateBody = ({ values, setFieldValue, isEditCase,errors }) => {
  const [anchorElVariable, setAnchorElVariable] = React.useState(null);
  const [variables, setVariables] = useState([]);

  const handleTextAreaChange = (e) => {
    const textarea = e?.target;
    const cursorPosition = textarea?.selectionStart; // Save the cursor position
    let updatedMessageBody = textarea?.value;

    // Regular expression to match variables inside double curly braces
    const variableRegex = /{{(.*?)}}/g;
    const matchedVariables = [...updatedMessageBody.matchAll(variableRegex)].map((match) => match[1]);

    const newVariables = [];
    matchedVariables.forEach((matchedVariable, index) => {
      // Replace invalid characters with underscores
      const sanitizedVariable = matchedVariable.replace(/[^a-zA-Z0-9_]/g, '_');

      // Replace the original variable in the message body with the sanitized version
      updatedMessageBody = updatedMessageBody.replace(`{{${matchedVariable}}}`, `{{${sanitizedVariable}}}`);

      // Check if the sanitized variable already exists
      const existingVariableIndex = variables.findIndex((variable) => variable.name === sanitizedVariable);

      if (existingVariableIndex !== -1) {
        newVariables.push(variables[existingVariableIndex]);
      } else {
        newVariables.push({ name: sanitizedVariable, sampleValue: '' });
      }
    });

    setVariables(newVariables);
    // Update the messageBodyTextArea field value and the Variables
    setFieldValue('messageBodyTextArea', updatedMessageBody);
    setFieldValue('variables', newVariables);

    // Restore the cursor position
    setTimeout(() => {
      textarea.setSelectionRange(cursorPosition, cursorPosition);
    }, 0);
  };

  const handleVariableChange = (index, field, e) => {
    const updatedVariables = [...variables];
    const oldName = updatedVariables[index].name;
    const newName = e.target.value;

    // Update the specific field in the variables array
    updatedVariables[index][field] = newName;

    // Update the variable name inside the messageBodyTextArea
    // if (field === 'name') {
    //   const regex = new RegExp(`{{${oldName}}}`, 'g');
    //   const newMessageBody = values.messageBodyTextArea.replace(regex, `{{${newName}}}`);
    //   setFieldValue('messageBodyTextArea', newMessageBody);
    //   setMessageBody(newMessageBody); // Update the local state for the text area
    // }
    setVariables(updatedVariables);
    setFieldValue('variables', updatedVariables);
  };

  const handleClickAddVariable = (event) => {
    setAnchorElVariable(event.currentTarget);
  };
  const handleCloseVariable = (variableName) => {
    if (variableName) {
      // Create a new variable object
      const newVariable = { name: variableName.toLowerCase().replace(/[^a-zA-Z0-9_]/g, '_'), sampleValue: '' };

      // Add the new variable to the list
      setVariables([...variables, newVariable]);

      // Get the textarea element by ID
      const textarea = document.getElementById('textarea-custom'); // Assuming the textarea has this ID

      // Get the current cursor position
      const cursorPosition = textarea.selectionStart;

      // Split the message body text at the cursor position
      const textBeforeCursor = values.messageBodyTextArea.slice(0, cursorPosition);
      const textAfterCursor = values.messageBodyTextArea.slice(cursorPosition);

      // Create the new message body text with the variable inserted at the cursor position
      const newMessageBody = `${textBeforeCursor}{{${newVariable.name}}}${textAfterCursor}`;

      // Update the messageBodyTextArea with the new text
      setFieldValue('messageBodyTextArea', newMessageBody);

      // Restore the cursor position after the inserted variable
      const newCursorPosition = cursorPosition + newVariable.name.length + 4; // +4 accounts for {{ and }}
      setTimeout(() => {
        textarea.setSelectionRange(newCursorPosition, newCursorPosition);
        textarea.focus();
      }, 0);
    }

    // Close the anchor element
    setAnchorElVariable(null);
  };
  return (
    <div className="msg-body-internal">
      <h4>
        Message body
        <Tooltip title="Type in the message you want to share with your users" arrow>
          <InfoOutlined fontSize="15px" />
        </Tooltip>
      </h4>
      <FormControl sx={{ display: 'block' }}>
        <TextareaCustom
          name="messageBodyTextArea"
          maxRows={10}
          id="textarea-custom"
          aria-label="maximum height"
          value={values.messageBodyTextArea}
          onChange={(e) => {
            handleTextAreaChange(e);
          }}
          placeholder={
            'Hi {{Name}}!👋. The awesome products😍 from {{Brand_Name}} are waiting to be yours! Get your products here👇'
          }
        />
      </FormControl>
      <div className="variable-btn">
        <Button
          variant="outlined"
          id="demo-positioned-button"
          aria-controls={anchorElVariable ? 'demo-positioned-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={anchorElVariable ? 'true' : undefined}
          onClick={handleClickAddVariable}
        >
          Add variables
        </Button>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorElVariable}
          open={Boolean(anchorElVariable)}
          onClose={() => handleCloseVariable(null)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem onClick={() => handleCloseVariable('Name')}>Name</MenuItem>
          <MenuItem onClick={() => handleCloseVariable('Email')}>Email</MenuItem>
          <MenuItem onClick={() => handleCloseVariable('Phone')}>Phone</MenuItem>
          <MenuItem onClick={() => handleCloseVariable('Custom')}>Custom</MenuItem>
        </Menu>
        <Tooltip title="Personalize your message by adding variables" arrow>
          <InfoOutlined fontSize="small" style={{ marginLeft: '8px' }} />
        </Tooltip>
      </div>

      {values?.variables.map((variable, index) => (
        <div className="variable-inner-body" key={index} style={{ display: 'flex', gap: '16px', marginTop: '16px' }}>
          <Box>
            {index === 0 && <CustomInputLabel sx={{ fontSize: '1.2rem' }}>Variable name</CustomInputLabel>}
            <TextField
              id="variable-name"
              placeholder="name"
              value={variable.name}
              onChange={(e) => handleVariableChange(index, 'name', e)}
              sx={{ backgroundColor: '#fff', borderRadius: '10px', input: { height: '5px' } }}
              InputProps={{
                readOnly: true,
              }}
            />
          </Box>
          <Box>
            {index === 0 && <CustomInputLabel sx={{ fontSize: '1.2rem' }}>Sample value</CustomInputLabel>}
            <TextField
              id="sample-value"
              placeholder="Ex: John, Ram, Riya"
              value={variable.sampleValue}
              onChange={(e) => handleVariableChange(index, 'sampleValue', e)}
              sx={{ backgroundColor: '#fff', borderRadius: '10px', input: { height: '5px' } }}
            />
          </Box>
        </div>
      ))}
      <div className="title-segment">
        <p>Enter a sample value for your variables to give context to the WhatsApp’s template approval team.</p>
      </div>
      <Divider />

      <div className="footer-body">
        <h4>
          Footer
          <Tooltip
            title="Add a short line of text to the bottom of your message template. If you add the marketing opt-out
                    button, the associated footer will be shown here by default."
            arrow
            placement="right"
          >
            <InfoOutlined fontSize="15px" />
          </Tooltip>
        </h4>
        <TextField
          fullWidth
          name="messageBodyFooterText"
          id="fullWidth"
          className="text-area"
          value={values?.messageBodyFooterText}
          onChange={(e) => {
            setFieldValue('messageBodyFooterText', e.target.value);
          }}
          error={errors?.messageBodyFooterText}
          helperText={errors?.messageBodyFooterText}
          placeholder="You can use this space to add a tagline, a way to unsubscribe, etc.,"
          sx={{ backgroundColor: '#fff', borderRadius: '10px', input: { height: '5px' } }}
        />
      </div>
    </div>
  );
};

export default WhatsAppTemplateBody;
