import React, { useEffect, useMemo, useState } from 'react';
import './Whatsapp.css';
import DemoOne from '../../assets/chart1.png';
import { getMapImageAndLink } from './WATemplateLocationInputSearch';
import { Box } from '@mui/material';
import {
  Reply as ReplyIcon,
  Launch as LaunchIcon,
  Phone as PhoneIcon,
} from '@mui/icons-material';
import FormatListBulletedSharpIcon from '@mui/icons-material/FormatListBulletedSharp';

const SVGIcons = {
  QUICK_REPLY: <ReplyIcon fontSize="14px" />,
  URL: <LaunchIcon fontSize="14px" />,
  PHONE_NUMBER: <PhoneIcon fontSize="14px" />,
};
const WhatsAppTemplateWA_Skeleton = ({ values, setFieldValue }) => {
  const formatMessageWithSpans = (message) => {
    return message.replace(/{{(.*?)}}/g, '<span class="abhi-style">$1</span>');
  };
  const MyComponent = ({ message }) => {
    const formattedMessage = formatMessageWithSpans(message);

    return <p dangerouslySetInnerHTML={{ __html: formattedMessage }}></p>;
  };
  const locationMapImage = useMemo(() => {
    const { latitude, longitude } = values;
    if (!latitude || !longitude) return null;

    const { image } = getMapImageAndLink(latitude, longitude, { height: 216, width: 216 });
    return (
      <Box sx={{ backgroundColor: '#e2e8f0', display: 'grid', gap: 0.5 }}>
        <img
          src={image}
          alt="Location Map"
          style={{ width: '100%', maxWidth: '216px', height: 'auto' }}
          onError={(e) => console.error('Failed to load image: ', e)}
        />
        {values?.name && (
          <p style={{ fontSize: '0.75rem', fontWeight: '800', color: '#1A202C', marginLeft: '5px' }}>{values?.name}</p>
        )}
        {values?.address && (
          <p style={{ fontSize: '0.75rem', color: '#4A5568', marginLeft: '5px' }}>{values?.address}</p>
        )}
      </Box>
    );
  }, [values.latitude, values.longitude]);
  return (
    <>
      <section className="whatsapp-skelton">
        <div className="container-chat">
          <div className="whats-top">
            <svg viewBox="0 0 24 24" focusable="false" className="whats-first-svg">
              <path fill="currentColor" d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"></path>
            </svg>
            <img
              src="data:image/svg+xml,%3csvg%20width='26'%20height='26'%20viewBox='0%200%2026%2026'%20fill='none'%20xmlns='http://www.w3.org/2000/svg'%3e%3cpath%20d='M0%2026L1.79309%2019.5C0.627605%2017.4828%200%2015.2414%200%2012.9103C0%205.8276%205.82761%200%2012.9552%200C20.0827%200%2026%205.8276%2026%2012.9103C26%2019.993%2020.0828%2026%2012.9552%2026C10.7138%2026%208.56207%2025.2828%206.63447%2024.2069L0%2026Z'%20fill='white'/%3e%3cpath%20d='M6.99838%2021.8857L7.40139%2022.1096C9.05816%2023.1395%2010.9836%2023.7664%2012.9986%2023.7664C18.8645%2023.7664%2023.7453%2018.8856%2023.7453%2012.9302C23.7453%206.97471%2018.8645%202.27307%2012.9538%202.27307C7.04317%202.27307%202.25195%207.0195%202.25195%2012.9302C2.25195%2014.9899%202.83407%2017.0049%203.90873%2018.7065L4.17742%2019.1095L3.14755%2022.8709L6.99838%2021.8857Z'%20fill='%2355CD6C'/%3e%3cpath%20d='M9.50703%206.99523L8.65374%206.95093C8.38425%206.95093%208.11481%207.03954%207.93517%207.21671C7.53096%207.57106%206.85731%208.23546%206.67766%209.12138C6.3633%2010.4502%206.85731%2012.0448%208.02501%2013.6394C9.19272%2015.2339%2011.4383%2017.803%2015.3904%2018.9103C16.6479%2019.2647%2017.636%2019.0432%2018.4444%2018.556C19.0732%2018.1573%2019.4774%2017.5372%2019.6121%2016.8728L19.7468%2016.2527C19.7918%2016.0755%2019.7019%2015.854%2019.5223%2015.7654L16.6929%2014.4809C16.5132%2014.3923%2016.2887%2014.4366%2016.1539%2014.6138L15.0311%2016.0312C14.9413%2016.1198%2014.8066%2016.1641%2014.6719%2016.1198C13.9084%2015.854%2011.3484%2014.791%209.95617%2012.1333C9.91124%2012.0005%209.91124%2011.8676%2010.0011%2011.779L11.0789%2010.5831C11.1688%2010.4502%2011.2137%2010.273%2011.1688%2010.1401L9.86637%207.26102C9.82139%207.1281%209.68667%206.99523%209.50703%206.99523Z'%20fill='%23FEFEFE'/%3e%3c/svg%3e"
              className="whats-first-pic"
            />
            <div className="whats-text-body">
              <p>demo changes</p>
              <img src="data:image/svg+xml,%3csvg%20width='18'%20height='18'%20viewBox='0%200%2018%2018'%20fill='none'%20xmlns='http://www.w3.org/2000/svg'%3e%3cpath%20d='M9%200L10.6462%201.78748L12.905%200.89128L13.6126%203.21601L16.0365%203.38859L15.6654%205.79013L17.7744%206.99731L16.398%209L17.7744%2011.0027L15.6654%2012.2099L16.0365%2014.6114L13.6126%2014.784L12.905%2017.1087L10.6462%2016.2125L9%2018L7.35379%2016.2125L5.09505%2017.1087L4.38742%2014.784L1.96352%2014.6114L2.33463%2012.2099L0.225649%2011.0027L1.602%209L0.225649%206.99731L2.33463%205.79013L1.96352%203.38859L4.38742%203.21601L5.09505%200.89128L7.35379%201.78748L9%200Z'%20fill='%2300DA60'/%3e%3cpath%20fill-rule='evenodd'%20clip-rule='evenodd'%20d='M13.6646%207.05978L8.21047%2012.173L5.28271%209.4282L6.0856%208.57179L8.21047%2010.5639L12.8617%206.20337L13.6646%207.05978Z'%20fill='white'/%3e%3c/svg%3e" />
            </div>
            <svg
              stroke="currentColor"
              fill="none"
              stroke-width="2"
              viewBox="0 0 24 24"
              strokeLinecap="round"
              stroke-linejoin="round"
              focusable="false"
              height="8"
              width="8"
              className="dot-svg"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="12" cy="12" r="1"></circle>
              <circle cx="12" cy="5" r="1"></circle>
              <circle cx="12" cy="19" r="1"></circle>
            </svg>
          </div>
          {(values?.headerText ||
            values?.messageBodyTextArea ||
            values?.filePreview ||
            values?.addedButtons?.length > 0 ||
            values?.latitude ||
            values?.longitude) && (
            <div className="text-body">
              <div className="body-parent">
                <div className="card-body">
                  {values?.messageHeaderType === 'TEXT' && values?.headerText && <h4>{values?.headerText}</h4>}
                  {/* Preview for images and videos */}
                  {values?.filePreview && values?.messageHeaderType === 'IMAGE' && (
                    <div>
                      <img src={values?.filePreview} alt="Uploaded Preview" style={{ maxWidth: '100%' }} />
                    </div>
                  )}

                  {values?.filePreview && values?.messageHeaderType === 'VIDEO' && (
                    <div>
                      <video controls style={{ maxWidth: '100%' }}>
                        <source src={values?.filePreview} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  )}

                  {/* Preview for documents */}
                  {values?.fileName && values?.messageHeaderType === 'DOCUMENT' && !values?.fileError && (
                    <div>
                      <p>Document Uploaded: {values?.fileName}</p>
                    </div>
                  )}
                  {(values?.latitude || values?.longitude) &&
                    values?.messageHeaderType === 'LOCATION' &&
                    locationMapImage}
                  <MyComponent message={values?.messageBodyTextArea} />
                  {values?.messageBodyFooterText && <p className="footer_msg_body">{values?.messageBodyFooterText}</p>}
                  <div className="action">
                    {values?.addedButtons?.slice(0, values?.addedButtons?.length > 3 ? 2 : 3).map((item, index) => (
                      <p key={item.id}>
                        <span>{SVGIcons[item.type || 'QUICK_REPLY']}</span>
                        {item.text || item.type}
                      </p>
                    ))}
                    {/* Show Explore More if there are more than 3 items */}
                    {values?.addedButtons?.length > 3 && (
                      <p>
                        <FormatListBulletedSharpIcon fontSize="14px" />
                        Explore more
                      </p>
                    )}
                  </div>
                </div>
                {/* <div className="img-slider">
                <div>
                  <img src={DemoOne} />
                  <img src={DemoOne} />
                  <img src={DemoOne} />
                </div>
              </div> */}
              </div>
            </div>
          )}
          <div className="whats-footer">
            <div className="foots-body">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 496 512"
                focusable="false"
                class="foot-one-svg"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm80 152c17.7 0 32 14.3 32 32s-14.3 32-32 32-32-14.3-32-32 14.3-32 32-32zm-160 0c17.7 0 32 14.3 32 32s-14.3 32-32 32-32-14.3-32-32 14.3-32 32-32zm88 272h-16c-73.4 0-134-55-142.9-126-1.2-9.5 6.3-18 15.9-18h270c9.6 0 17.1 8.4 15.9 18-8.9 71-69.5 126-142.9 126z"></path>
              </svg>
              <p class="whats-msg">Message</p>
              <div class="side-txt"></div>
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                version="1.1"
                viewBox="0 0 16 16"
                focusable="false"
                class="foot-second-svg"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M10.404 5.11l-1.015-1.014-5.075 5.074c-0.841 0.841-0.841 2.204 0 3.044s2.204 0.841 3.045 0l6.090-6.089c1.402-1.401 1.402-3.673 0-5.074s-3.674-1.402-5.075 0l-6.394 6.393c-0.005 0.005-0.010 0.009-0.014 0.013-1.955 1.955-1.955 5.123 0 7.077s5.123 1.954 7.078 0c0.004-0.004 0.008-0.009 0.013-0.014l0.001 0.001 4.365-4.364-1.015-1.014-4.365 4.363c-0.005 0.004-0.009 0.009-0.013 0.013-1.392 1.392-3.656 1.392-5.048 0s-1.392-3.655 0-5.047c0.005-0.005 0.009-0.009 0.014-0.013l-0.001-0.001 6.395-6.393c0.839-0.84 2.205-0.84 3.045 0s0.839 2.205 0 3.044l-6.090 6.089c-0.28 0.28-0.735 0.28-1.015 0s-0.28-0.735 0-1.014l5.075-5.075z"></path>
              </svg>
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 1024 1024"
                focusable="false"
                class="foot-third-svg"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M864 260H728l-32.4-90.8a32.07 32.07 0 0 0-30.2-21.2H358.6c-13.5 0-25.6 8.5-30.1 21.2L296 260H160c-44.2 0-80 35.8-80 80v456c0 44.2 35.8 80 80 80h704c44.2 0 80-35.8 80-80V340c0-44.2-35.8-80-80-80zM512 716c-88.4 0-160-71.6-160-160s71.6-160 160-160 160 71.6 160 160-71.6 160-160 160zm-96-160a96 96 0 1 0 192 0 96 96 0 1 0-192 0z"></path>
              </svg>
            </div>
            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAsCAYAAAAehFoBAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAARcSURBVHgB1ZlfbFNVHMe/594CMktS1pdBfLjEbiOahZJoRA3ZhT0IxEgfIMrQbHvySadvZJvxEkfVtwWffJFpZCNi4iQxBhK3Nf6JJCarGRh1JbsaRCVsacIyYKz38PvdrU3L1na995ZePsl2zz331/bb09/5nd/5HQEXaEYsFAjUtQJSh4AmIKLUHVr+Y9JSwqTnaWonATG+uDifMI2RNBwi4IBI/EgrLBhC5ARWhIQcgRSDqb6hr1EhFQmO9L98UAhlgF6mwROkaUkYV/qGP13rK9YkePv7r2iLGXGKRlRHdUguSsSm+4b+KmeoljNoPNHeLaU4Q2K3o3o00Mh1htueuD07evliKcOSgiMn2t+lN/qAmo+gygjBn6Hsq297ErOjlxLF7IoKXhZr4AFDkUYvJXpVwbUSm6WU6BWC2WeX3aCmsGjy6fT9Pl0QJTgaZCxlAg5iazWgRSedAaL50UPJNyCxX8EnYhmaiCEVcjC/Lye4KX6kgy5R+AyO/ZH+9oPZ+5xgGn4DPkUIOZBr8z/+BjT8I3BJcMNGvPHcIezWdtj335u/4tQv3+C/m7NwixRST/UMJwL2nUAnPOCTQz3Ysimcuz/Q/Kx9/+a5AbjGsj1gj6IZnSEa5hhcEt3aVCA2y07qj25thHtElNNZJRBYaIUHbNlUX+JZGG7hiMG5N026qmVgVUDqHCV8F8qKQrsahUKGbxaKcvAWjEdYw0MCrRUhFuxqhOMvvG7H33KwDdu6gSeeApfs3rYDwfV1Ze3Yhm3dwoIdb7mZf2/OoIFC2tTM1aI2E9emyCZMK94M3MDZG006d4J5+d3fvAupG1fxxeToiufcx0L3Nz9jC3cDaTXVzXtbdFrpHG8w/07/j27KHy5fn8a5336wxQU31Nlf4HTyAk5PXLBXuu7nD6Pn/MeYW7gFxwj5e4CyCpMacAoLPPnTl/jopbdx8sezODs5hm//+Nl+xhOt66kDONyyF/Gxz9y7hIWk8CpT41yid89reHT9RqTIn3mUG4L11P7Hztgmrv0Jt5APxwQnP+vWLUzDo51GY/gxdD39ov3uLLTUZKyUu3fnNyumMUgFO5GER7DAuTvz9tVLsVyP4yLiUhxWFAMewqGORXsKFQ/5kts10/ae3UKDPzGneoe2cSN/T/cWfIolhZFtF9QlIv1Hxyh70+EvkjS6O7M3BblEQM10weVS7S0izWXY/J6CUtWN7y6lw20tt6m5D/7g2JW+ofP5HStqa7Ojkxfr21rYV3TUEAnLSPUOf3h//6rVSxKdqKXoJbFnjq/2rGh9uFaiS4llSlbgWTT5NE/CXah6FV7w5xxbzQ0KrLAGlsqwKlU2ZXV22ILO7yzZuZZDmYqOvZriRzuklAa8WxFNXrAqOa9zdLD4+HuvxlTV6pDSSYmLfnqBpMyI46l3Ph9HhTgSnIVrXaoa1IXItApFidKM4RRVI9cJZcXRymkf21qWpNFUE5nM3Libo9t7LyuaRd+zYwwAAAAASUVORK5CYII=" />
          </div>
        </div>
      </section>
    </>
  );
};

export default WhatsAppTemplateWA_Skeleton;
