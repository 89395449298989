import React, { useEffect, useState } from 'react';
import {Button, Tabs, Tab, Box} from '@mui/material';
import './whatsapptemplate.css';
import { useNavigate } from 'react-router';
import { PATH_APP } from 'src/routes/paths';
import axios from '../../utils/axios';
import { ApiEndpoints } from 'src/config';
import WhatsAppTemplateHome_Library from './WhatsAppTemplateHome_Library';
import WATemplatesMyTemplates from './WATemplatesMyTemplates';
import Iconify from 'src/components/Iconify';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0.5 }}>{children}</Box>}
    </div>
  );
}
const WhatsAppTemplateHomeScreen = () => {
  const [value, setValue] = useState(0);
  const [whatsappDataLibrary, setwhatsappDataLibrary] = useState([]);
  const [code,setCode] = useState(null);
  const [endPagination, setEndPagination] = useState(false)
  const [loader, setLoader] = useState(false)
  const [myTemplates,setMyTemplates] = useState();

  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const getWhatsappTemplateLibrary = async (afterCode) => {
    setLoader(true)
    try {
      const response = await axios.get(`${ApiEndpoints.WA_TEMPLATES_LIBRARY}${afterCode ? `?after=${afterCode}` : ''}`);
      
      if (response?.data?.status === 200) {
        const newData = response?.data?.data?.data || [];
        const paging = response?.data?.data?.paging || {};
        // Update state with the new data concatenated with previous data
        setwhatsappDataLibrary(prevData => [...prevData, ...newData]);
        setCode(paging);
        if(paging?.cursors?.after == null){
            setEndPagination(true)
        }
      }
      setLoader(false)
    } catch (error) {
        setLoader(false)
      console.error('Error fetching data:', error);
    }
  };
const getWhatsAppMytemplates = () => {
  setLoader(true);
  axios.get(`${ApiEndpoints.WA_TEMPLATE_CREATE}?page=1&size=10`).then((response) => {
    if(response?.data?.status === 200){
      const data = response?.data?.data?.data || [];
      setMyTemplates(data);
      setLoader(false);
    }else{
      setLoader(false) ;
    }
  }).catch((error) => {
    setLoader(false);
    console.error('Error fetching',error)
  });
}
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if(value === 1){
      getWhatsappTemplateLibrary(code?.cursors?.after);
    }else if(value === 0){
    getWhatsAppMytemplates();
    }
  }, [value]);

  return (
    <section className="main">
      <section className="whatsapptemplates">
        <container>
          <div className="header">
            <h1>WhatsApp Templates</h1>
            <p>
              <Button variant="contained" onClick={() => navigate(PATH_APP.management.waTemplatesNew)} startIcon={<Iconify icon={'eva:plus-fill'} />}>
              Create new Template
            </Button>
            </p>
          </div>
        </container>
      </section>
      <section className="whatsapp-listing-page">
        <div>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="My Templates" {...a11yProps(0)} />
                <Tab label="Library" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <WATemplatesMyTemplates handleOpen={handleOpen} loader={loader} myTemplates={myTemplates} handleClose={handleClose} open={open} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <WhatsAppTemplateHome_Library 
                whatsappDataLibrary={whatsappDataLibrary} 
                loader={loader}
                getWhatsappTemplateLibrary = {getWhatsappTemplateLibrary}
                code={code}
                endPagination={endPagination}
              />
            </CustomTabPanel>
          </Box>
        </div>
        <div className="text-whatspp">
          <p>Template that gets approved can be used only after 15 minutes of approval</p>
        </div>
      </section>
    </section>
  );
};

export default WhatsAppTemplateHomeScreen;
